#entry-container {
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100dvh;
    width: 100dvw;
}

#entry-container .entry-navigation {
    position: relative;
    z-index: 999;
    color: #fff;
    mix-blend-mode: difference;
}

#entry-container .entry-navigation h2 {
    margin-top: 0;
    margin-bottom: 0;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 5vw;
}

#entry-container .entry-navigation h2 > span {
    color: #ff0000;
}

@media screen and (max-width: 768px) {
    #entry-container .entry-navigation {
        mix-blend-mode: normal;
    }
    
    #entry-container .entry-navigation h2 {
        font-size: 8vw;
    }

    #entry-container .entry-navigation a {
        color: #fff;
    }

    #entry-container .entry-navigation h2 > span {
        color: #ff0000;
    }
}