#contact-container header {
    display: flex;
    align-items: center;
    width: 100dvw;
    justify-content: space-between;
}

#contact-container header > a {
    font-size: x-large;
    color: #000;
}

#contact-container header > a > h1 {
    margin-top: 0;
}

#contact-container .contact-links-container {
    width: 100dvw;
    height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5dvw;
    padding-right: 5dvw;
}

#contact-container .contact-links-container ul {
    list-style: none;
    padding: 0;
    margin-top: 0;
    columns: 1;
}

#contact-container .contact-links-container a {
    color: #000;
    text-transform: uppercase;
    font-size: 5dvw;
    font-weight: lighter;
    font-style: normal;
}

@media screen and (max-width: 768px) {
    #contact-container header {
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        padding-left: 5vw;
    }

    #contact-container header > a > h1 {
        font-size: xx-large;
    }

    #navigation ul {
        width: 90dvw;
        justify-content: flex-start;
    }

    #navigation ul > li {
        margin-right: 2.5rem;
    }

    #navigation a {
        font-size: medium;
    }

    #contact-container .contact-links-container {
        height: 80dvh;
        padding: 0;
    }

    #contact-container .contact-links-container a {
        font-size: 10dvw;
    }
}