.image {
    width: 30vmin;
    position: absolute;
    transform: translate(-50%, -50%);
    filter: grayscale(100%);
}

.image[data-status="inactive"] {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.2s ease, visibility 0s linear 0.2s;
    -moz-transition: opacity 0.2s ease, visibility 0s linear 0.2s;
    -ms-transition: opacity 0.2s ease, visibility 0s linear 0.2s;
    -o-transition: opacity 0.2s ease, visibility 0s linear 0.2s;
    transition: opacity 0.2s ease, visibility 0s linear 0.2s;
}

.image[data-status="active"] {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.2s ease, visibility 0s linear;
    -moz-transition: opacity 0.2s ease, visibility 0s linear;
    -ms-transition: opacity 0.2s ease, visibility 0s linear;
    -o-transition: opacity 0.2s ease, visibility 0s linear;
    transition: opacity 0.2s ease, visibility 0s linear;
}

@media screen and (max-width: 768px) {
    .image {
        top: 40vh;
    }
}