#face-container header {
    position: fixed;
    display: flex;
    align-items: center;
    width: 100dvw;
    justify-content: space-between;
    z-index: 999;
    mix-blend-mode: difference;
    background-color: #fff;
}

#face-container header > a {
    font-size: x-large;
    color: #000;
}

#face-container header > a > h1 {
    margin-top: 0;
    text-transform: uppercase;
}

#face-container .images-container {
    position: relative;
    height: auto;
    width: 90dvw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1vw;
    column-gap: 1vw;
    padding: 5vw;
}

#face-container .images-container .face-image {
    width: 100%;
}

@media screen and (max-width: 768px) {
    #face-container header {
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        padding-left: 5vw;
    }

    #face-container header > a > h1 {
        font-size: xx-large;
    }

    #navigation ul {
        width: 90dvw;
        justify-content: flex-start;
    }

    #navigation ul > li {
        margin-right: 2.5rem;
    }

    #navigation a {
        font-size: medium;
    }

    #face-container .images-container {
        padding-top: 15dvh;
        grid-template-columns: 1fr;
        row-gap: 5vh;
    }
}